import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Head from 'react-helmet';

import { Link, useLocation } from 'react-router-dom';

import {
  CollectionIcon,
  ClipboardListIcon,
  PhoneIcon,
  XIcon,
  MenuAlt2Icon,
  LogoutIcon,
  DocumentSearchIcon,
  CalendarIcon,
} from '@heroicons/react/outline';

import { useAuth } from '../Context/AuthContext';
const userNavigation = [
  { name: 'Impianti', href: '/impianti', icon: CollectionIcon },
  { name: 'Documentazione', href: '/documentazione', icon: DocumentSearchIcon },
  { name: 'Ricambi', href: '/listino', icon: ClipboardListIcon },
  { name: 'Chiamate', href: '/chiamate', icon: PhoneIcon },
  { name: 'Calendario', href: '/calendario', icon: CalendarIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Layout = ({ children, title, toolbar: Toolbar }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { logout } = useAuth();
  const { pathname } = useLocation();

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <div className="h-full flex">
        {/* Narrow sidebar */}
        <div className="hidden w-28 bg-indigo-700 overflow-y-auto md:flex md:flex-col md:justify-between">
          <div className="w-full py-6 flex flex-col items-center">
            {/* <div className="flex-shrink-0 flex items-center">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                alt="Workflow"
              />
            </div> */}
            <div className="flex-1 mt-6 w-full px-2 space-y-1">
              {userNavigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    pathname === item.href
                      ? 'bg-indigo-800 text-white'
                      : 'text-indigo-100 hover:text-white',
                    'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium',
                  )}
                  aria-current={item.current ? 'page' : undefined}>
                  <item.icon
                    className={classNames(
                      pathname === item.href
                        ? 'text-white'
                        : 'text-indigo-300 group-hover:text-white',
                      'h-6 w-6',
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2">{item.name}</span>
                </Link>
              ))}
            </div>
          </div>
          <button
            onClick={logout}
            className={
              'text-red-500 hover:text-red-300 group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
            }>
            <LogoutIcon
              className={'text-red-500 group-hover:text-red-300 h-6 w-6'}
              aria-hidden="true"
            />
          </button>
        </div>

        {/* Mobile menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog as="div" className="md:hidden" onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <div className="relative max-w-xs w-full bg-indigo-700 pt-5 pb-4 flex-1 flex flex-col">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute top-1 right-0 -mr-14 p-1">
                      <button
                        type="button"
                        className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setMobileMenuOpen(false)}>
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        <span className="sr-only">Close sidebar</span>
                      </button>
                    </div>
                  </Transition.Child>
                  {/* <div className="flex-shrink-0 px-4 flex items-center">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                      alt="Workflow"
                    />
                  </div> */}
                  <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                    <nav className="h-full flex flex-col">
                      <div className="space-y-1">
                        {userNavigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              pathname === item.href
                                ? 'bg-indigo-800 text-white'
                                : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
                              'group py-2 px-3 rounded-md flex items-center text-sm font-medium',
                            )}
                            aria-current={item.current ? 'page' : undefined}>
                            <item.icon
                              className={classNames(
                                pathname === item.href
                                  ? 'text-white'
                                  : 'text-indigo-300 group-hover:text-white',
                                'mr-3 h-6 w-6',
                              )}
                              aria-hidden="true"
                            />
                            <span>{item.name}</span>
                          </Link>
                        ))}
                      </div>
                                 <button
            onClick={logout}
            className={
              'text-red-500 hover:text-red-300 group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
            }>
            <LogoutIcon
              className={'text-red-500 group-hover:text-red-300 h-6 w-6'}
              aria-hidden="true"
            />
          </button>
                    </nav>
                  </div>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="flex-1 flex flex-col overflow-hidden bg-white">
          <header className="w-full">
            <div className="relative flex-shrink-0 bg-white border-b border-gray-200 shadow-sm flex py-2">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setMobileMenuOpen(true)}>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 flex justify-between px-4 sm:px-6">{Toolbar}</div>
            </div>
          </header>

          <div className="flex-1 flex items-stretch overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              <section
                aria-labelledby="primary-heading"
                className="min-w-0 flex-1 h-full flex flex-col lg:order-last">
                {children}
              </section>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
